import { Card, Col } from "react-bootstrap";

interface Props {
  clickCallback?: () => void;
  children?: any;
  title?: string;
  text?: string;
  variant?: string;
}

const GlobalCard = ({ clickCallback, children, title, text, variant }: Props): JSX.Element => {
  return (
    <Col>
      <Card
        bg={variant ?? ""}
        style={{ width: 200, height: 100, alignItems: "center" }}
        as='button'
        onClick={() => {
          clickCallback?.();
        }}
      >
        <Card.Body>
          {title && <Card.Title>{title}</Card.Title>}
          {text && <Card.Text>{text}</Card.Text>}
          {children || null}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default GlobalCard;
