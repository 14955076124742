import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import KnoxPage from "./KnoxPage";

const Auth0Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  const urlParams = new URLSearchParams(window.location.search);

  const error = urlParams.get("error");
  const errorDescription = urlParams.get("error_description");

  return (
    <KnoxPage>
      {error ? (
        <Alert className='m-2' variant='danger'>
          <Alert.Heading>{error.toLocaleUpperCase()}</Alert.Heading>
          {errorDescription}
        </Alert>
      ) : null}
      <h1>
        <Button
          onClick={() => {
            void loginWithRedirect();
          }}
        >
          Log In to KNOX
        </Button>
      </h1>
    </KnoxPage>
  );
};

export default Auth0Login;
