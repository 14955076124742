import { useEffect } from "react";
import { useAppSelector } from "../hooks";
import { getClients } from "../Functions";
import ClientDetailsModal from "./ClientDetailsModal";
import ClientsList from "./ClientsList";

const AllClients = (): JSX.Element => {
  const reduxClientState = useAppSelector((state) => state.client);
  useEffect(() => {
    void getClients();
  }, []);
  return (
    <div className='mb-auto mt-2'>
      <ClientsList clientList={reduxClientState.clientList} />
      {reduxClientState.selectedClient && (
        <ClientDetailsModal client={reduxClientState.selectedClient} />
      )}
    </div>
  );
};

export default AllClients;
