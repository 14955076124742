import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Project } from "../Types";

export interface ProjectsState {
  projectList: Project[];
  selectedProject?: Project;
  editing: boolean;
}

const initialState: ProjectsState = {
  projectList: [],
  selectedProject: undefined,
  editing: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number) => {
//     const response = { data: 55 };
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.projectList = action.payload;
    },
    updateProjectInRedux: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload;
      state.projectList = state.projectList.map((project) =>
        project.id === action.payload.id ? action.payload : project,
      );
      state.editing = false;
    },
    addProject: (state, action: PayloadAction<Project>) => {
      state.projectList = [...state.projectList, action.payload];
    },
    deleteProjectInRedux: (state, action: PayloadAction<string>) => {
      state.projectList = state.projectList.filter((project) => project.id !== action.payload);
      state.selectedProject = undefined;
      if (state.editing) {
        state.editing = false;
      }
    },
    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload;
    },
    clearSelectedProject: (state) => {
      state.selectedProject = undefined;
      if (state.editing) {
        state.editing = false;
      }
    },
    setProjectEditing: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {})
  //     .addCase(incrementAsync.fulfilled, (state, action) => {})
  //     .addCase(incrementAsync.rejected, (state) => {});
  // },
});

export const {
  setProjects,
  addProject,
  updateProjectInRedux,
  deleteProjectInRedux,
  setSelectedProject,
  clearSelectedProject,
  setProjectEditing,
} = projectSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default projectSlice.reducer;
