import { useEffect } from "react";
import { getProjects } from "../Functions";
import { useAppSelector } from "../hooks";
import ProjectDetailsModal from "./ProjectDetailsModal";
import ProjectsList from "./ProjectsList";

const AllProjects = (): JSX.Element => {
  const reduxProjectState = useAppSelector((state) => state.project);
  useEffect(() => {
    void getProjects();
  }, []);
  return (
    <div className='mb-auto mt-2'>
      <ProjectsList projectList={reduxProjectState.projectList} />
      {reduxProjectState.selectedProject && (
        <ProjectDetailsModal project={reduxProjectState.selectedProject} />
      )}
    </div>
  );
};

export default AllProjects;
