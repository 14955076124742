import { Form, Row, Col } from "react-bootstrap";
import { type Application, type Client, type ProjectEdit } from "../../Types";

interface Props {
  formData: ProjectEdit;
  handleDataChange: (e: any) => void;
  clientList: Client[];
  applicationList: Application[];
}

const EditProjectDetails = ({
  formData,
  handleDataChange,
  clientList,
  applicationList,
}: Props): JSX.Element => {
  return (
    <Form>
      <Form.Group key={"edit-project-client-id"} as={Row}>
        <Form.Label column>Client</Form.Label>
        <Col>
          <Form.Select
            name='clientId'
            onChange={handleDataChange}
            value={formData["clientId" as keyof ProjectEdit]}
          >
            <option key='no-client-option' value='none'>
              None
            </option>
            {clientList.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group key={"edit-project-application-id"} as={Row}>
        <Form.Label column>Application</Form.Label>
        <Col>
          <Form.Select
            name='applicationId'
            onChange={handleDataChange}
            value={formData["applicationId" as keyof ProjectEdit]}
          >
            <option key='no-application-option' value='none'>
              None
            </option>
            {applicationList.map((application) => (
              <option key={application.id} value={application.id}>
                {application.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      {[
        { label: "OAuth Client Id", name: "oAuthClientId" },
        { label: "OAuth Client Secret", name: "oAuthClientSecret" },
      ].map((i) => (
        <Form.Group key={"edit-project-" + i.name} as={Row}>
          <Form.Label column>{i.label}</Form.Label>
          <Col>
            <Form.Control
              name={i.name}
              onChange={handleDataChange}
              value={formData[i.name as keyof ProjectEdit]}
            />
          </Col>
        </Form.Group>
      ))}
    </Form>
  );
};

export default EditProjectDetails;
