import { getApplication } from "../../Functions";
import { useAppDispatch } from "../../hooks";
import { setSelectedApplication } from "../../Slices/applicationSlice";
import { type Application } from "../../Types";
import GlobalCard from "../Globals/GlobalCard";

interface Props {
  application: Application;
}

const ApplicationCard = ({ application }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <GlobalCard
      clickCallback={() => {
        void getApplication(application.id, (r) => dispatch(setSelectedApplication(r)));
      }}
      title={application.name}
    />
  );
};

export default ApplicationCard;
