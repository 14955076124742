import { toast } from "react-toastify";
import { setApplications, updateApplicationInRedux } from "./Slices/applicationSlice";
import { setClients } from "./Slices/clientSlice";
import { setProjects } from "./Slices/projectSlice";
import { store } from "./store";
import { type Application, type AuthRequestRequiredParams, type FetchInput } from "./Types";

const apiBaseUrl: string = window?._env_?.REACT_APP_KNOX_API ?? "";

export const customFetch = async ({
  endpoint,
  config = {},
  skipParseBody = false,
}: FetchInput): Promise<any> => {
  if (apiBaseUrl) {
    const res = await fetch(apiBaseUrl + endpoint, {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorage.auth0Bearer as string}`,
      },
    });

    if (res.status === 403) {
      toast.warning("What are you doing? You are not allowed to access this!", {
        toastId: "status_403",
      });
      return null;
    } else if (res.status > 399) {
      toast.warning("Failed! - " + res.statusText, {
        toastId: "error",
      });
      return null;
    }
    return skipParseBody ? res : await res.json();
  } else {
    throw new Error("No Endpoint");
  }
};

export const getApplications = async (): Promise<void> => {
  const result = await customFetch({
    endpoint: "/api/Application",
  });
  if (result) store.dispatch(setApplications(result));
};

export const getClients = async (): Promise<void> => {
  const result = await customFetch({
    endpoint: "/api/Client",
  });
  if (result) store.dispatch(setClients(result));
};

export const getProjects = async (): Promise<void> => {
  const result = await customFetch({
    endpoint: "/api/Project",
  });
  if (result) store.dispatch(setProjects(result));
};

export const getAuthRequestRequiredParamsInstance = async (
  authRequestRequiredParamsFormData: AuthRequestRequiredParams,
  callback: (r: AuthRequestRequiredParams) => void | Promise<void>,
): Promise<void> => {
  const arrp = await customFetch({
    endpoint: "/api/AuthRequestRequiredParam",
    config: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(authRequestRequiredParamsFormData),
    },
  });
  if (arrp) await callback(arrp);
};

export const getApplication = async (
  applicationId: string,
  callback: (r: Application) => void,
): Promise<void> => {
  const result = await customFetch({
    endpoint: "/api/Application/" + applicationId,
  });
  if (result) {
    store.dispatch(updateApplicationInRedux(result));
    callback(result);
  }
};
