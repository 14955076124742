import { useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { customFetch, getAuthRequestRequiredParamsInstance } from "../../Functions";
import { useAppDispatch } from "../../hooks";
import { addApplication } from "../../Slices/applicationSlice";
import {
  type ApplicationEdit,
  type AuthRequestRequiredParams,
  type IDictionary,
} from "../../Types";
import EditApplicationDetails from "./EditApplicationDetails";
import GlobalCard from "../Globals/GlobalCard";

const defaultFormData: ApplicationEdit = { implimentationOption: 1 };

const NewApplicationCard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [addingNew, setAddingNew] = useState(false);
  const [formData, setFormData] = useState<ApplicationEdit>(defaultFormData as IDictionary);
  const [authRequestRequiredParamsFormData, setAuthRequestRequiredParamsFormData] =
    useState<AuthRequestRequiredParams>({
      code: false,
      clientId: false,
      clientSecret: false,
      redirectUri: false,
      grantType: false,
    });

  const handleDataChange = (event: any): void => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.name === "implimentationOption"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  };

  const handleAuthRequestRequiredParamsChange = (event: any): void => {
    setAuthRequestRequiredParamsFormData({
      ...authRequestRequiredParamsFormData,
      [event.target.name]: event.target.checked,
    });
  };

  const clearAndClose = (): void => {
    setFormData(defaultFormData);
    setAddingNew(false);
  };

  const createApplication = async (application: ApplicationEdit): Promise<void> => {
    const r = await customFetch({
      endpoint: "/api/Application",
      config: {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(application),
      },
    });
    if (r) {
      clearAndClose();
      dispatch(addApplication(r));
    }
  };

  const handleSubmit = async (): Promise<void> => {
    await getAuthRequestRequiredParamsInstance(
      authRequestRequiredParamsFormData,
      (r: AuthRequestRequiredParams) => {
        void createApplication({
          ...formData,
          authRequestRequiredParamsId: r.id,
        });
      },
    );
  };

  return (
    <>
      <GlobalCard
        clickCallback={() => {
          setAddingNew(true);
        }}
        title='New Application'
        variant='info'
      />
      <Modal size='lg' show={addingNew}>
        <Modal.Header>Add New Application</Modal.Header>
        <Modal.Body>
          <EditApplicationDetails
            formData={formData}
            authRequestRequiredParamsFormData={authRequestRequiredParamsFormData}
            handleDataChange={handleDataChange}
            handleAuthRequestRequiredParamsChange={handleAuthRequestRequiredParamsChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Stack direction='horizontal' gap={2}>
            <Button onClick={clearAndClose} variant='secondary'>
              Cancel
            </Button>
            <Button
              disabled={!formData.name || !formData.authURL || !formData.tokenURL}
              onClick={() => {
                void handleSubmit();
              }}
            >
              Add New Application
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewApplicationCard;
