import { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { setApplicationEditing } from "../../Slices/applicationSlice";
import { type Application, type AuthRequestRequiredParams } from "../../Types";

interface Props {
  application: Application;
}

const ApplicationDetailsTable = ({ application }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const startEditing = (): any => dispatch(setApplicationEditing(true));

  const authValues = useMemo(() => {
    const ARRP = application.authRequestRequiredParams;
    if (!ARRP) {
      return "Not Found";
    } else {
      const keys = Object.keys(ARRP).filter(
        (key) => key !== "id" && ARRP[key as keyof AuthRequestRequiredParams],
      );
      return keys.join(", ");
    }
  }, [application, application.authRequestRequiredParams]);

  return (
    <Table bordered style={{ tableLayout: "fixed" }}>
      <tbody>
        {[
          { label: "Name", value: application.name },
          {
            label: "Auth URL",
            value: application.authURL,
          },
          {
            label: "Token URL",
            value: application.tokenURL,
          },
          {
            label: "Auth Request Params",
            value: authValues,
          },
          {
            label: "Token Aqquired Via",
            value: application.implimentationOption === 1 ? "JSON" : "x-ww-form-urlencoded",
          },
        ].map((element, index) => (
          <tr key={element.label + index.toString()}>
            <th>{element.label}</th>
            {
              <td onClick={startEditing} style={{ wordWrap: "break-word" }}>
                {element.value}
              </td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ApplicationDetailsTable;
