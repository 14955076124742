import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { type ApplicationEdit } from "../../Types";

const applicationProps = [
  { label: "Name", value: "name" },
  {
    label: "Email Address",
    value: "emailAddress",
  },
];

interface Props {
  formData: ApplicationEdit;
  handleDataChange: (e: any) => void;
}

const EditClientDetails = ({ formData, handleDataChange }: Props): JSX.Element => {
  return (
    <Form>
      {applicationProps.map((i, index) => (
        <Form.Group as={Row} key={index}>
          <Form.Label column>{i.label}</Form.Label>
          <Col>
            <Form.Control
              name={i.value}
              onChange={(e: any) => {
                handleDataChange(e);
              }}
              value={formData[i.value as keyof ApplicationEdit]}
            />
          </Col>
        </Form.Group>
      ))}
    </Form>
  );
};

export default EditClientDetails;
