import { useAuth0 } from "@auth0/auth0-react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

const SideNav = (): JSX.Element => {
  const path = window.location.pathname;
  const { logout } = useAuth0();

  return (
    <Nav
      style={{
        borderRadius: 5,
        backgroundColor: "lightgray",
        height: window.innerHeight * 0.8,
      }}
      className='flex-column m-2 p-2'
    >
      <Stack gap={2}>
        <Button
          href='/projects'
          variant={path === "/projects" ? "outline-secondary" : "outline-primary"}
        >
          Projects
        </Button>
        <Button
          href='/applications'
          variant={path === "/applications" ? "outline-secondary" : "outline-primary"}
        >
          Applications
        </Button>
        <Button
          href='/clients'
          variant={path === "/clients" ? "outline-secondary" : "outline-primary"}
        >
          Clients
        </Button>
        <Button
          className='mt-auto'
          variant='outline-danger'
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          Log Out
        </Button>
      </Stack>
    </Nav>
  );
};

export default SideNav;
