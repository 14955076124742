import { useEffect, useState } from "react";
import KnoxPage from "./KnoxPage";

const db: string = window?._env_?.REACT_APP_KNOX_API ?? "";

let ran: boolean = false;

const AcceptRedirectPage = (): JSX.Element => {
  const [status, setStatus] = useState("Waiting");
  const searchParams = new URLSearchParams(window.location.search);
  const recievedCode = searchParams.get("code");
  const recievedProjectId = searchParams.get("state") ?? "";

  useEffect(() => {
    if (!ran && db && recievedCode && recievedProjectId) {
      ran = true;
      void fetch(`${db}/api/Project/${recievedProjectId}/AcceptCode`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recievedCode),
      })
        .then((res) => {
          setStatus(res.status === 200 ? "Success" : "Failed");
        })
        .catch(() => {
          setStatus("Failed");
        });
    }
    if (!recievedCode || !recievedProjectId) {
      setStatus("Invalid URL");
    }
  }, [status, recievedCode, recievedProjectId]);

  return (
    <KnoxPage>
      <h1>{status}</h1>
      {status === "Success" && <h2>You may close this window</h2>}
    </KnoxPage>
  );
};

export default AcceptRedirectPage;
