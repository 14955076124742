import Spinner from "react-bootstrap/Spinner";
import KnoxPage from "./KnoxPage";

const LoadingPage = (): JSX.Element => {
  return (
    <KnoxPage>
      <Spinner />
    </KnoxPage>
  );
};

export default LoadingPage;
