import Container from "react-bootstrap/Container";
import { BsShieldLockFill } from "react-icons/bs";

interface Props {
  children?: React.ReactNode;
}

const KnoxPage = ({ children }: Props): JSX.Element => {
  return (
    <Container className='text-center p-5'>
      <BsShieldLockFill size={500} />
      <div className='m-5'>{children}</div>
      <h1>KNOX</h1>
      <h6>By Spearhead</h6>
    </Container>
  );
};

export default KnoxPage;
