import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import SideNav from "../Components/SideNav";
import { useAuth0 } from "@auth0/auth0-react";
import { db } from "../..";
import LoadingPage from "./LoadingPage";
import Auth0Login from "./Auth0Login";

export default function HomePage(): JSX.Element {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserMetadata = async (): Promise<void> => {
      if (user?.sub) {
        try {
          localStorage.auth0Bearer = await getAccessTokenSilently({
            audience: db,
          });
          window.location.pathname === "/" && navigate("/projects");
        } catch (e: any) {
          console.log(e.message);
        }
      }
    };

    void getUserMetadata();
  }, [getAccessTokenSilently, user, user?.sub]);

  return isLoading ? (
    <div className={isLoading ? "custom-loading" : ""}>
      <LoadingPage />
    </div>
  ) : isAuthenticated ? (
    <Stack gap={3} direction='horizontal'>
      <SideNav />
      <Outlet />
    </Stack>
  ) : (
    <Auth0Login />
  );
}
