import Row from "react-bootstrap/Row";
import ClientCard from "../Components/Client/ClientCard";
import NewClientCard from "../Components/Client/NewClientCard";
import { type Client } from "../Types";

interface Props {
  clientList: Client[];
}

const ClientsList = ({ clientList }: Props): JSX.Element => {
  return (
    <Row sm={1} md={2} className='g-4'>
      <NewClientCard />
      {clientList.map((client) => (
        <ClientCard key={client.id} client={client} />
      ))}
    </Row>
  );
};

export default ClientsList;
