import { useEffect } from "react";
import { getApplications } from "../Functions";
import { useAppSelector } from "../hooks";
import ApplicationDetailsModal from "./ApplicationDetailsModal";
import ApplicationsList from "./ApplicationsList";

const AllApplications = (): JSX.Element => {
  const reduxApplicationState = useAppSelector((state) => state.application);
  useEffect(() => {
    void getApplications();
  }, []);
  return (
    <div className='mb-auto mt-2'>
      <ApplicationsList applicationList={reduxApplicationState.applicationList} />
      {reduxApplicationState.selectedApplication && (
        <ApplicationDetailsModal application={reduxApplicationState.selectedApplication} />
      )}
    </div>
  );
};

export default AllApplications;
