import { useAppDispatch } from "../../hooks";
import { setSelectedClient } from "../../Slices/clientSlice";
import { type Client } from "../../Types";
import GlobalCard from "../Globals/GlobalCard";

interface Props {
  client: Client;
}

const ClientCard = ({ client }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <GlobalCard clickCallback={() => dispatch(setSelectedClient(client))} title={client.name} />
  );
};

export default ClientCard;
