import { Row } from "react-bootstrap";
import NewProjectCard from "../Components/Project/NewProjectCard";
import ProjectCard from "../Components/Project/ProjectCard";
import { type Project } from "../Types";

interface IAppProps {
  projectList: Project[];
}

export default function ProjectsList(props: IAppProps): JSX.Element {
  return (
    <Row sm={1} md={2} className='g-4'>
      <NewProjectCard />
      {props.projectList.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </Row>
  );
}
