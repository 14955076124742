import { Table } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { setClientEditing } from "../../Slices/clientSlice";
import { type Client } from "../../Types";

interface Props {
  client: Client;
}

const ClientDetailsTable = ({ client }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Table bordered>
      <tbody>
        {[
          { label: "Name", value: client.name },
          {
            label: "Email",
            value: client.emailAddress,
          },
        ].map((element, index) => (
          <tr key={element.label + index.toString()}>
            <th>{element.label}</th>
            <td onClick={() => dispatch(setClientEditing(true))}>{element.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ClientDetailsTable;
