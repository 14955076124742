import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";

const TruncatedTableCell = ({
  content,
  startEditing,
}: {
  content: string;
  startEditing: () => void;
}): JSX.Element => {
  const [visibility, setVisibilty] = useState(false);
  const toggleVisibility = (e: React.MouseEvent<SVGElement, MouseEvent>): void => {
    e.stopPropagation();
    setVisibilty((v) => !v);
  };
  const copyToClipboard = (e: React.MouseEvent<SVGElement, MouseEvent>): void => {
    e.stopPropagation();
    void navigator.clipboard.writeText(content);
    toast.success("Copied to your clipboard!");
  };

  return (
    <td onClick={startEditing} style={{ wordBreak: "break-all" }}>
      {visibility ? content : content ? content.slice(0, 5) + "*****" : null}
      {visibility ? (
        <AiFillEye className='float-end' style={{ cursor: "pointer" }} onClick={toggleVisibility} />
      ) : (
        <AiFillEyeInvisible
          className='float-end'
          style={{ cursor: "pointer" }}
          onClick={toggleVisibility}
        />
      )}
      <MdContentCopy
        className='float-end'
        style={{ cursor: "pointer" }}
        onClick={copyToClipboard}
      />
    </td>
  );
};

export default TruncatedTableCell;
