import { configureStore, type ThunkAction, type Action } from "@reduxjs/toolkit";
import projectsReducer from "./Slices/projectSlice";
import applicationsReducer from "./Slices/applicationSlice";
import clientsReducer from "./Slices/clientSlice";
import configReducer from "./Slices/configSlice";

export const store = configureStore({
  reducer: {
    project: projectsReducer,
    application: applicationsReducer,
    client: clientsReducer,
    config: configReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
