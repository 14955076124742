import { useAppDispatch } from "../../hooks";
import { setSelectedProject } from "../../Slices/projectSlice";
import { type Project } from "../../Types";
import GlobalCard from "../Globals/GlobalCard";

export interface IAppProps {
  project: Project;
}

export default function ProjectCard(props: IAppProps): JSX.Element {
  const dispatch = useAppDispatch();
  const applicationName = props.project.application
    ? props.project.application.name
    : "No Application Found";
  const clientName = props.project.client ? props.project.client.name : "No Client Found";

  return (
    <GlobalCard
      clickCallback={() => dispatch(setSelectedProject(props.project))}
      title={applicationName}
      text={clientName}
    />
  );
}
