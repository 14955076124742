import { useState } from "react";
import { Modal } from "react-bootstrap";
import EditProjectDetails from "../Components/Project/EditProjectDetails";
import ProjectControls from "../Components/Project/ProjectControls";
import ProjectDetailsTable from "../Components/Project/ProjectDetailsTable";
import ProjectModalHeader from "../Components/Project/ProjectModalHeader";
import { customFetch } from "../Functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearSelectedProject,
  updateProjectInRedux,
  setProjectEditing,
  deleteProjectInRedux,
} from "../Slices/projectSlice";
import { type Project } from "../Types";
import { toast } from "react-toastify";

interface Props {
  project: Project;
}

const ProjectDetailsModal = ({ project }: Props): JSX.Element => {
  const reduxState = useAppSelector((state) => state);
  const editingProject = reduxState.project.editing;
  const dispatch = useAppDispatch();

  const [deleting, setDeleting] = useState(false);
  const [formData, setFormData] = useState(project);

  const handleDataChange = (event: any): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const authenticateProject = async (): Promise<void> => {
    const result = await customFetch({
      endpoint: "/api/Project/" + project.id + "/AuthenticateProject",
      config: {
        method: "POST",
      },
      skipParseBody: true,
    });
    if (result) toast.success("Email has been sent");
  };

  const updateProject = async (): Promise<void> => {
    const result = await customFetch({
      endpoint: "/api/Project/" + project.id,
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
    });
    if (result) {
      dispatch(updateProjectInRedux(result));
      setFormData(result);
    }
  };

  const deleteProject = async (): Promise<void> => {
    const result = await customFetch({
      endpoint: "/api/Project/" + project.id,
      config: {
        method: "DELETE",
      },
      skipParseBody: true,
    });
    if (result) dispatch(deleteProjectInRedux(project.id));
  };

  return (
    <Modal size='lg' onHide={() => dispatch(clearSelectedProject())} show={!!project}>
      {project?.id ? (
        <>
          <ProjectModalHeader project={project} />
          <Modal.Body>
            {!editingProject ? (
              <ProjectDetailsTable project={project} />
            ) : (
              <EditProjectDetails
                formData={formData}
                handleDataChange={handleDataChange}
                clientList={reduxState.client.clientList}
                applicationList={reduxState.application.applicationList}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ProjectControls
              editing={editingProject}
              deleting={deleting}
              editCallback={() => dispatch(setProjectEditing(true))}
              requestAuthCallback={() => {
                void authenticateProject();
              }}
              cancelCallback={() => {
                if (editingProject) {
                  setFormData(project);
                  dispatch(setProjectEditing(false));
                } else {
                  setDeleting(false);
                }
              }}
              saveChangesCallback={() => {
                void updateProject();
              }}
              deletingCallback={() => {
                if (deleting) {
                  void deleteProject();
                } else setDeleting(true);
              }}
            />
          </Modal.Footer>
        </>
      ) : (
        "Error"
      )}
    </Modal>
  );
};

export default ProjectDetailsModal;
