import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import HomePage from "./app/Pages/HomePage";
import AcceptRedirectPage from "./app/Pages/AcceptRedirectPage";
import AllApplications from "./app/Containers/AllApplications";
import AllProjects from "./app/Containers/AllProjects";
import AllClients from "./app/Containers/AllClients";
import KnoxPage from "./app/Pages/KnoxPage";

export const db: string = window?._env_?.REACT_APP_KNOX_API ?? ""; // Don't remove!

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0Provider
        domain={window?._env_?.REACT_APP_KNOX_DOMAIN ?? ""}
        clientId={window?._env_?.REACT_APP_KNOX_CLIENT_ID ?? ""}
        redirectUri={window.location.origin}
        audience={db}
        scope={window?._env_?.REACT_APP_KNOX_SCOPE ?? ""}
      >
        <HomePage />
      </Auth0Provider>
    ),
    children: [
      {
        path: "/projects",
        element: <AllProjects />,
      },
      {
        path: "/applications",
        element: <AllApplications />,
      },
      {
        path: "/clients",
        element: <AllClients />,
      },
    ],
    errorElement: (
      <KnoxPage>
        <p className='bg-danger'>We ran into a problem, please try again</p>
      </KnoxPage>
    ),
  },
  { path: "/accept-redirect", element: <AcceptRedirectPage /> },
]);

function App(): JSX.Element {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position='top-right'
        closeOnClick={true}
        pauseOnHover={true}
        autoClose={2500}
        hideProgressBar={true}
      />
    </>
  );
}

export default App;
