import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ApplicationDetailsTable from "../Components/Application/ApplicationDetailsTable";
import EditApplicationDetails from "../Components/Application/EditApplicationDetails";
import ResourceControls from "../Components/Globals/ResourceControls";
import { customFetch, getAuthRequestRequiredParamsInstance } from "../Functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearSelectedApplication,
  deleteApplicationInRedux,
  setApplicationEditing,
  updateApplicationInRedux,
} from "../Slices/applicationSlice";
import { type Application, type AuthRequestRequiredParams } from "../Types";

interface Props {
  application: Application;
}

const ApplicationDetailsModal = ({ application }: Props): JSX.Element => {
  const editingApplication = useAppSelector((state) => state.application.editing);
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const [formData, setFormData] = useState<Application>(application);
  const [authRequestRequiredParamsFormData, setAuthRequestRequiredParamsFormData] =
    useState<AuthRequestRequiredParams>(
      application.authRequestRequiredParams as AuthRequestRequiredParams,
    );

  const handleDataChange = (event: any): void => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.name === "implimentationOption"
          ? parseInt(event.target.value)
          : event.target.value,
    });
  };

  const handleAuthRequestRequiredParamsChange = (event: any): void => {
    setAuthRequestRequiredParamsFormData({
      ...authRequestRequiredParamsFormData,
      [event.target.name]: event.target.checked,
    });
  };

  const updateApplication = async (formData: Application): Promise<void> => {
    const r = await customFetch({
      endpoint: "/api/Application/" + application.id,
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
    });
    if (r) {
      dispatch(updateApplicationInRedux(r));
      setFormData(r);
    }
  };

  const handleUpdate = async (): Promise<void> => {
    if (authRequestRequiredParamsFormData !== application.authRequestRequiredParams) {
      await getAuthRequestRequiredParamsInstance(
        authRequestRequiredParamsFormData,
        async (r: AuthRequestRequiredParams) => {
          await updateApplication({
            ...formData,
            authRequestRequiredParamsId: r.id ?? "",
            authRequestRequiredParams: r,
          });
        },
      );
    } else {
      await updateApplication(formData);
    }
  };

  const deleteApplication = async (): Promise<void> => {
    const r = await customFetch({
      endpoint: "/api/Application/" + application.id,
      config: {
        method: "DELETE",
      },
      skipParseBody: true,
    });
    if (r) dispatch(deleteApplicationInRedux(application.id));
  };

  useEffect(() => {
    editingApplication && setDeleting(false);
  }, [editingApplication]);

  return (
    <Modal size='xl' onHide={() => dispatch(clearSelectedApplication())} show={!!application}>
      {application?.id ? (
        <>
          <Modal.Header>{application.name}</Modal.Header>
          <Modal.Body>
            {!editingApplication ? (
              <ApplicationDetailsTable application={application} />
            ) : (
              <EditApplicationDetails
                formData={formData}
                authRequestRequiredParamsFormData={authRequestRequiredParamsFormData}
                handleDataChange={handleDataChange}
                handleAuthRequestRequiredParamsChange={handleAuthRequestRequiredParamsChange}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ResourceControls
              deleting={deleting}
              editing={editingApplication}
              editCallback={() => dispatch(setApplicationEditing(true))}
              cancelCallback={() => {
                editingApplication ? dispatch(setApplicationEditing(false)) : setDeleting(false);
              }}
              deletingCallback={() => {
                if (deleting) {
                  void deleteApplication();
                } else setDeleting(true);
              }}
              saveChangesCallback={() => {
                void handleUpdate();
              }}
            />
          </Modal.Footer>
        </>
      ) : (
        "Error"
      )}
    </Modal>
  );
};

export default ApplicationDetailsModal;
