import { useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { customFetch } from "../../Functions";
import { useAppDispatch } from "../../hooks";
import { addClient } from "../../Slices/clientSlice";
import { type ClientEdit, type IDictionary } from "../../Types";
import EditClientDetails from "./EditClientDetails";
import GlobalCard from "../Globals/GlobalCard";

const NewClientCard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [addingNew, setAddingNew] = useState(false);
  const [formData, setFormData] = useState<ClientEdit>({} satisfies IDictionary);

  const handleDataChange = (event: any): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const clearAndClose = (): void => {
    setFormData({});
    setAddingNew(false);
  };

  const handleSubmit = async (): Promise<void> => {
    const r = await customFetch({
      endpoint: "/api/Client",
      config: {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      },
    });
    if (r) {
      clearAndClose();
      dispatch(addClient(r));
    }
  };
  return (
    <>
      <GlobalCard
        clickCallback={() => {
          setAddingNew(true);
        }}
        title='New Client'
        variant='info'
      />
      <Modal size='lg' show={addingNew}>
        <Modal.Header>Add New Client</Modal.Header>
        <Modal.Body>
          <EditClientDetails formData={formData} handleDataChange={handleDataChange} />
        </Modal.Body>
        <Modal.Footer>
          <Stack direction='horizontal' gap={2}>
            <Button onClick={clearAndClose} variant='secondary'>
              Cancel
            </Button>
            <Button
              disabled={!formData.name || !formData.emailAddress}
              onClick={() => {
                void handleSubmit();
              }}
            >
              Add New Client
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewClientCard;
