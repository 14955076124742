import { Table } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { setProjectEditing } from "../../Slices/projectSlice";
import { type Project } from "../../Types";
import TruncatedTableCell from "../Globals/TruncatedTableCell";

interface Props {
  project: Project;
}

const ProjectDetailsTable = ({ project }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const startEditing = (): any => dispatch(setProjectEditing(true));
  return (
    <Table bordered>
      <tbody>
        {[
          { label: "Project ID", value: project.id },
          { label: "OAuth Client Id", value: project.oAuthClientId },
          {
            label: "OAuth Client Secret",
            value: project.oAuthClientSecret,
          },
          {
            label: "OAuth Refresh Token",
            value: project.oAuthRefreshToken,
          },
        ].map((element, index) => (
          <tr key={element.label + index.toString()}>
            <th>{element.label}</th>
            <TruncatedTableCell startEditing={startEditing} content={element.value} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProjectDetailsTable;
