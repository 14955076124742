import { ModalHeader } from "react-bootstrap";
import { type Project } from "../../Types";

interface Props {
  project: Project;
}

const ProjectModalHeader = (props: Props): JSX.Element => {
  return (
    <ModalHeader>
      {props.project.client?.name} using {props.project.application?.name}
    </ModalHeader>
  );
};

export default ProjectModalHeader;
