import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { customFetch, getApplications, getClients } from "../../Functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addProject } from "../../Slices/projectSlice";
import { type ProjectEdit } from "../../Types";
import EditProjectDetails from "./EditProjectDetails";
import GlobalCard from "../Globals/GlobalCard";

const NewProjectCard = (): JSX.Element => {
  const reduxState = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [addingNew, setAddingNew] = useState(false);
  const [formData, setFormData] = useState<ProjectEdit>({});

  const handleDataChange = (event: any): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (): Promise<void> => {
    const r = await customFetch({
      endpoint: "/api/Project",
      config: {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      },
    });
    if (r) {
      dispatch(addProject(r));
      clearAndCloseNewProjectModal();
    }
  };

  const startNewProject = (): void => {
    void getApplications();
    setAddingNew(true);
  };

  const clearAndCloseNewProjectModal = (): void => {
    setFormData({});
    setAddingNew(false);
  };

  useEffect(() => {
    !reduxState.application.applicationList[0] && getApplications();
    !reduxState.client.clientList[0] && getClients();
  }, []);

  return (
    <>
      <GlobalCard clickCallback={startNewProject} title='New Project' variant='info' />
      <Modal size='lg' show={addingNew} onHide={clearAndCloseNewProjectModal}>
        <Modal.Header>Add New Project</Modal.Header>
        <Modal.Body>
          <EditProjectDetails
            formData={formData}
            handleDataChange={handleDataChange}
            clientList={reduxState.client.clientList}
            applicationList={reduxState.application.applicationList}
          />
        </Modal.Body>
        <Modal.Footer>
          <Stack direction='horizontal' gap={2}>
            <Button
              onClick={() => {
                setFormData({});
                setAddingNew(false);
              }}
              variant='secondary'
            >
              Cancel
            </Button>
            <Button
              disabled={
                !formData.applicationId ||
                !formData.clientId ||
                !formData.oAuthClientId ||
                !formData.oAuthClientSecret
              }
              onClick={() => {
                void handleSubmit();
              }}
            >
              Add New Project
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewProjectCard;
