import { Form, Row, Col } from "react-bootstrap";
import { type ApplicationEdit, type AuthRequestRequiredParams } from "../../Types";
import { type ChangeEvent } from "react";

const authRequestRequiredParams = [
  { label: "Auth In Header", value: "authInHeader" },
  { label: "Redirect URI", value: "redirectUri" },
  { label: "Grant Type", value: "grantType" },
  { label: "Client ID", value: "clientId" },
  { label: "Client Secret", value: "clientSecret" },
];

const applicationProps = [
  { label: "Name", value: "name" },
  {
    label: "Auth URL (Do not include the 'state' parameter)",
    value: "authURL",
  },
  { label: "Token URL", value: "tokenURL" },
];

interface Props {
  formData: ApplicationEdit;
  authRequestRequiredParamsFormData: AuthRequestRequiredParams;
  handleDataChange: (e: ChangeEvent) => void;
  handleAuthRequestRequiredParamsChange: (e: ChangeEvent) => void;
}

const EditApplicationDetails = ({
  formData,
  authRequestRequiredParamsFormData,
  handleDataChange,
  handleAuthRequestRequiredParamsChange,
}: Props): JSX.Element => {
  return (
    <Form>
      {applicationProps.map((i, index) => (
        <Form.Group as={Row} key={index}>
          <Form.Label column>{i.label}</Form.Label>
          <Col>
            <Form.Control
              name={i.value}
              onChange={(e: ChangeEvent) => {
                handleDataChange(e);
              }}
              value={formData[i.value as keyof ApplicationEdit]}
              as={i.label.includes("URL") ? "textarea" : "input"}
              style={{
                whiteSpace: "pre-wrap",
                resize: "none",
              }}
            />
          </Col>
        </Form.Group>
      ))}
      <Form.Group as={Row} key={"implimentation-options"}>
        <Form.Label column>Token Aqquired Via</Form.Label>
        <Col>
          <Form.Select
            name={"implimentationOption"}
            onChange={(e: ChangeEvent) => {
              handleDataChange(e);
            }}
            value={formData.implimentationOption}
          >
            <option value={1}>JSON</option>
            <option value={2}>x-ww-form-urlencoded</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='border m-1 p-2 rounded-1'>
        <Form.Label column>Auth Request Params:</Form.Label>
        <div className='mb-3'>
          {authRequestRequiredParams.map((e, index) => (
            <Form.Check
              key={index}
              inline
              label={e.label}
              checked={
                !!authRequestRequiredParamsFormData[e.value as keyof AuthRequestRequiredParams]
              }
              name={e.value}
              onChange={(i: ChangeEvent) => {
                handleAuthRequestRequiredParamsChange(i);
              }}
            />
          ))}
        </div>
      </Form.Group>
    </Form>
  );
};

export default EditApplicationDetails;
