import { Button, Stack } from "react-bootstrap";

interface Props {
  cancelCallback: () => void;
  saveChangesCallback: () => void;
  editing: boolean;
  editCallback: () => void;
  deleting: boolean;
  deletingCallback: () => void;
}

const ResourceControls = ({
  editing,
  deleting,
  deletingCallback,
  cancelCallback,
  editCallback,
  saveChangesCallback,
}: Props): JSX.Element => {
  return (
    <Stack style={{ width: "100%" }} direction='horizontal' gap={2}>
      {(editing || deleting) && (
        <Button
          variant='secondary'
          onClick={() => {
            cancelCallback();
          }}
        >
          Cancel
        </Button>
      )}
      {!editing && !deleting && (
        <Button
          variant='outline-warning'
          onClick={() => {
            editCallback();
          }}
        >
          Edit
        </Button>
      )}
      {editing && (
        <Button
          variant='warning'
          onClick={() => {
            saveChangesCallback();
          }}
        >
          Save Changes
        </Button>
      )}
      {!editing && (
        <Button
          className='ms-auto'
          variant={deleting ? "danger" : "outline-danger"}
          onClick={() => {
            deletingCallback();
          }}
        >
          {deleting ? "Confirm Delete" : "Delete"}
        </Button>
      )}
    </Stack>
  );
};

export default ResourceControls;
