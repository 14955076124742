import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ClientDetailsTable from "../Components/Client/ClientDetailsTable";
import EditClientDetails from "../Components/Client/EditClientDetails";
import ResourceControls from "../Components/Globals/ResourceControls";
import { customFetch } from "../Functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearSelectedClient,
  setClientEditing,
  updateClientInRedux,
  deleteClientInRedux,
} from "../Slices/clientSlice";
import { type Client } from "../Types";

interface Props {
  client: Client;
}

const ClientDetailsModal = ({ client }: Props): JSX.Element => {
  const editingClient = useAppSelector((state) => state.client.editing);
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const [formData, setFormData] = useState(client);

  const handleDataChange = (event: any): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateClient = async (formData: Client): Promise<void> => {
    const result = await customFetch({
      endpoint: "/api/Client/" + client.id,
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
    });
    if (result) {
      await dispatch(updateClientInRedux(result));
      setFormData(result);
    }
  };

  const deleteClient = async (): Promise<void> => {
    const result = await customFetch({
      endpoint: "/api/Client/" + client.id,
      config: {
        method: "DELETE",
      },
      skipParseBody: true,
    });
    if (result) dispatch(deleteClientInRedux(client.id));
  };

  useEffect(() => {
    editingClient && setDeleting(false);
  }, [editingClient]);

  return (
    <Modal size='xl' onHide={() => dispatch(clearSelectedClient())} show={!!client}>
      {client?.id ? (
        <>
          <Modal.Header>{client.name}</Modal.Header>
          <Modal.Body>
            {!editingClient ? (
              <ClientDetailsTable client={client} />
            ) : (
              <EditClientDetails formData={formData} handleDataChange={handleDataChange} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ResourceControls
              deleting={deleting}
              editing={editingClient}
              editCallback={() => dispatch(setClientEditing(true))}
              cancelCallback={() => {
                editingClient ? dispatch(setClientEditing(false)) : setDeleting(false);
              }}
              deletingCallback={() => {
                if (deleting) {
                  void deleteClient();
                } else setDeleting(true);
              }}
              saveChangesCallback={() => {
                void updateClient(formData);
              }}
            />
          </Modal.Footer>
        </>
      ) : (
        "Error"
      )}
    </Modal>
  );
};

export default ClientDetailsModal;
