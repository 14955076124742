import { Button, Stack } from "react-bootstrap";

interface Props {
  editing: boolean;
  deleting: boolean;
  deletingCallback: () => void;
  editCallback: () => void;
  requestAuthCallback: () => void;
  cancelCallback: () => void;
  saveChangesCallback: () => void;
}

const ProjectControls = ({
  editing,
  deleting,
  editCallback,
  requestAuthCallback,
  cancelCallback,
  saveChangesCallback,
  deletingCallback,
}: Props): JSX.Element => {
  return (
    <Stack style={{ width: "100%" }} direction='horizontal' gap={2}>
      {!editing && !deleting && (
        <>
          <Button
            variant='outline-warning'
            onClick={() => {
              editCallback();
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              requestAuthCallback();
            }}
          >
            Request User Authentication
          </Button>
        </>
      )}
      {(editing || deleting) && (
        <Button
          variant='secondary'
          onClick={() => {
            cancelCallback();
          }}
        >
          Cancel
        </Button>
      )}
      {editing && (
        <Button
          variant='warning'
          onClick={() => {
            saveChangesCallback();
          }}
        >
          Save Changes
        </Button>
      )}
      {!editing && (
        <Button
          className='ms-auto'
          variant={deleting ? "danger" : "outline-danger"}
          onClick={() => {
            deletingCallback();
          }}
        >
          {deleting ? "Confirm Delete" : "Delete"}
        </Button>
      )}
    </Stack>
  );
};

export default ProjectControls;
