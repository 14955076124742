import { Row } from "react-bootstrap";
import ApplicationCard from "../Components/Application/ApplicationCard";
import NewApplicationCard from "../Components/Application/NewApplicationCard";
import { type Application } from "../Types";

interface Props {
  applicationList: Application[];
}

const ApplicationsList = ({ applicationList }: Props): JSX.Element => {
  return (
    <Row sm={1} md={2} className='g-4'>
      <NewApplicationCard />
      {applicationList.map((application) => (
        <ApplicationCard key={application.id} application={application} />
      ))}
    </Row>
  );
};

export default ApplicationsList;
